import { useEffect, useRef } from 'react';
import CareerGoal from '../CareerGoal/CareerGoal';
import Certifications from '../Certifications/Certifications';
import Education from '../Education/Education';
import Experience from '../Experience/Experience';
import Intro from '../Intro/Intro';
import Links from '../Links/Links';
import Contact from '../Contact/Contact';
import Awards from '../Awards/Awards';
import Projects from '../../Projects/Projects';
import Publications from '../Publications/Publications';
// import TechnicalSkills from '../TechnicalSkills/TechnicalSkills';

function App() {
  const isMountedRef = useRef(null);

  useEffect(() => {
    if (isMountedRef.current) {
      isMountedRef.current = null;
      const fetchCount = async () => {
        try {
          const res = await fetch('/api/load', {
            method: 'POST',
            headers: {
              'x-env': window.origin,
            },
          });
          if (!res.ok) throw res.statusText;
        } catch (error) {
          console.error(error);
        }
      };

      fetchCount();
    }
  }, [isMountedRef]);

  return (
    <main ref={isMountedRef}>
      <Links />
      <Intro />
      <CareerGoal />
      {/* <TechnicalSkills /> */}
      <Experience />
      <Education />
      <Certifications />
      <Awards />
      <Projects />
      <Publications />
      <Links footer />
      <Contact />
    </main>
  );
}

export default App;
