import data from './data';

const Projects = () => {
  return (
    <section>
      <div className='mx-auto max-w-screen-lg'>
        <h1 className='mb-8 text-center'>Projects</h1>
        {data.map((project, i) => (
          <div key={i} className='mb-8 last-of-type:mb-0'>
            <div className='mb-2 sm:flex sm:items-center sm:justify-between'>
              <h2>{project.name}</h2>
              {/* <p className='hidden text-base italic sm:block'>{project.date}</p> */}
            </div>
            <p className='bg-light text-dark mb-2 inline-block rounded-lg'>
              <a
                href={project.link}
                target='_blank'
                rel='noreferrer'
                className='px-4'
              >
                {project.platform}
              </a>
            </p>
            {/* <p className='mb-2 text-base italic sm:hidden'>{project.date}</p> */}
            <p>{project.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};
export default Projects;
