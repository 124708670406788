const data = [
  {
    name: 'AI & ML Technical Leadership Program',
    institution: 'Codesmith',
    year: '2025',
    description:
      '4-week program, and ongoing leadership circle, for hands-on AI and ML development with business and ethics training.',
  },
  {
    name: 'Professional Scrum Master II',
    institution: 'Scrum.org',
    year: '2024',
    description:
      'Gained depth of knowledge pertaining to the Scrum framework and its application.',
  },
  // {
  //   name: 'Project Management Life Cycle',
  //   institution: 'Rochester Institute of Technology',
  //   year: '2024',
  //   description:
  //     'Gained depth of knowledge in project life cycle management, focusing on time, cost, and scope',
  // },
  // {
  //   name: 'Project Management Program',
  //   institution: 'Coursera',
  //   year: '2022',
  //   description:
  //     '6-course program to gain competence in initiating, planning, and running both traditional and agile projects',
  // },
  {
    name: 'Professional Scrum Master I',
    institution: 'Scrum.org',
    year: '2022',
    description:
      'Gained depth of knowledge pertaining to the Scrum framework and its application.',
  },
  // {
  //   name: 'Automation Program',
  //   institution: 'Coursera',
  //   year: '2021',
  //   description:
  //     '6-course program to gain competence in automation at scale by using cloud configuration management',
  // },
  {
    name: 'Advanced Software Engineering Immersive Program',
    institution: 'Hack Reactor',
    year: '2020',
    description:
      '6-month full stack JavaScript development curriculum with lectures, pair programming, and mentorship.',
  },
  {
    name: 'Professional Music Certificate',
    institution: 'Berklee College of Music',
    year: '2004',
    description:
      '2-year diverse concentration in music performance, songwriting, production, law, and business.',
  },
];

export default data;
