const data = [
  {
    name: 'AI Communication Coach',
    platform: 'YouTube',
    link: 'https://www.youtube.com/watch?v=RJUtkKnL1Nw',
    date: '01/2025',
    description:
      'Communication coaching with simultaneous AI coach participants.',
  },
  {
    name: 'Texting OpenAI',
    platform: 'GitHub',
    link: 'https://github.com/WarrenMfg/openai-sms',
    date: '01/2023',
    description: 'Text OpenAI a prompt to generate a response.',
  },
  {
    name: 'wordie CLI',
    platform: 'npm',
    link: 'https://www.npmjs.com/package/wordie',
    date: '11/2022',
    description: 'CLI dictionary and thesaurus using Datamuse.',
  },
];

export default data;
