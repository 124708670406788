const data = [
  {
    title: '3 Chords',
    date: '11/2010',
    publisher: 'Fools & Horses, LLC',
    platform: 'YouTube',
    link: 'https://www.youtube.com/watch?v=32va-LZ0VTg',
  },
  {
    title: 'Fools & Horses',
    date: '09/2010',
    publisher: 'Fools & Horses, LLC',
    platform: 'Spotify',
    link: 'https://open.spotify.com/album/3WOYK8ri6e0LVXUP2TzdQX',
  },
  {
    title: 'I Am the Ghost',
    date: '10/2007',
    publisher: 'Fools & Horses, LLC',
    platform: 'Spotify',
    link: 'https://open.spotify.com/album/3cYqxvG3oqW3ycsBY6qwYi',
  },
  {
    title: 'Off the Clock',
    date: '06/2007',
    publisher: 'Starbucks',
    platform: 'Apple Music',
    link: 'https://music.apple.com/us/album/off-the-clock-vol-1/216529225',
  },
  {
    title: 'Pop Filter: Rethoughts',
    date: '01/2006',
    publisher: 'Fools & Horses, LLC',
    platform: 'Spotify',
    link: 'https://open.spotify.com/album/2bmjpeilZ5TZyB5GeOFrxN',
  },
  {
    title: 'Pop Filter',
    date: '11/2005',
    publisher: 'Fools & Horses, LLC',
    platform: 'Spotify',
    link: 'https://open.spotify.com/album/74CraLuG2gJ8gibyWXdaHP',
  },
  {
    title: 'Saturday Morning',
    date: '04/2003',
    publisher: 'Fools & Horses, LLC',
    platform: 'Spotify',
    link: 'https://open.spotify.com/album/6EjpogQNmkidlbV0fmWaBH',
  },
];

export default data;
