import cn from 'classnames';
// import 'media-chrome';
import Media from '../Media/Media';
import { data, highlights } from './data';
import './Experience.module.css';

const Experience = () => {
  return (
    <section>
      <div className='mx-auto max-w-screen-lg'>
        <h1 className='mb-16 text-center'>Experience</h1>

        <Highlights />

        {data.map((job, i) => (
          <div
            key={i}
            className={cn(
              'last-of-type:mb-0',
              job.media.length ? 'mb-8' : 'mb-10'
            )}
          >
            <div className='mb-2 sm:flex sm:items-center sm:justify-between'>
              {/* position */}
              <h2 className='sm:mr-4'>{job.position}</h2>

              {/* desktop dates */}
              <p className='hidden whitespace-nowrap text-base italic sm:block'>
                {job.dates[0]} &ndash; {job.dates[1]}
              </p>
            </div>

            {/* company */}
            <p className='bg-light text-dark mb-2 inline-block rounded-lg px-4'>
              {job.company}
            </p>

            {/* mobile dates */}
            <p className='mb-1 text-base italic sm:hidden'>
              {job.dates[0]} &ndash; {job.dates[1]}
            </p>

            {/* tech */}
            {job.tech.length > 0 && (
              <p className='mb-2 text-sm italic lg:text-base'>
                {job.tech.join(' | ')}
              </p>
            )}

            {/* bullet points */}
            <ul className='ml-6 mb-4 list-disc'>
              {job.bullets.map((bullet, i) => (
                <li key={i} className='mb-2 lg:mb-1'>
                  {bullet}
                </li>
              ))}
            </ul>

            {/* media */}
            {job.media.length > 0 && <Media media={job.media} />}
          </div>
        ))}
      </div>
    </section>
  );
};
export default Experience;

const Highlights = () => (
  <div className='bg-dark-alt mx-auto mb-16 max-w-screen-lg rounded-2xl p-4 lg:p-8'>
    <h2 className='mb-4 text-center lg:mb-2'>Highlights</h2>
    {/* <div className='mb-8'> */}
    {highlights.map(({ category, bulletPoints }, i) => (
      <div key={i}>
        <p className='mb-2 font-bold lg:mb-1'>{category}</p>
        <ul
          className={`ml-6 list-disc ${
            i + 1 === highlights.length ? '' : 'mb-4'
          }`}
        >
          {bulletPoints.map((bulletPoint, i) => (
            <li
              key={i}
              className={i + 1 === bulletPoints.length ? '' : 'mb-2 lg:mb-1'}
            >
              {bulletPoint}
            </li>
          ))}
        </ul>
      </div>
    ))}
    {/* </div> */}
    {/* <AudioPlayer /> */}
  </div>
);

// const AudioPlayer = () => {
//   return (
//     <div>
//       <h2 className='mb-3 text-center lg:mb-2'>NotebookLM Podcast</h2>

//       <div className='flex justify-center'>
//         <media-controller audio>
//           {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
//           <audio
//             slot='media'
//             preload='auto'
//             src='/audio/highlights.mp3'
//           ></audio>
//           <media-control-bar>
//             <media-play-button
//               notooltip
//               class='[--media-icon-color:theme(colors.light)]'
//             ></media-play-button>
//             <media-time-display
//               showduration
//               class='[--media-primary-color:theme(colors.light)]'
//             ></media-time-display>
//             <media-time-range class='[--media-primary-color:theme(colors.light)]'>
//               <span slot='preview'></span>
//             </media-time-range>
//           </media-control-bar>
//         </media-controller>
//       </div>
//     </div>
//   );
// };
