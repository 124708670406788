import data from './data';

const Awards = () => {
  return (
    <section>
      <div className='mx-auto max-w-screen-lg'>
        <h1 className='mb-8 text-center'>Awards</h1>
        {data.map((award, i) => (
          <div key={i} className='mb-8 last-of-type:mb-0'>
            <div className='mb-2 sm:flex sm:items-center sm:justify-between'>
              <h2>{award.name}</h2>
              {/* <p className='hidden text-base italic sm:block'>{award.date}</p> */}
            </div>
            <p className='bg-dark text-light mb-2 inline-block rounded-lg px-4'>
              {award.issuer}
            </p>
            {/* <p className='mb-2 text-base italic sm:hidden'>{award.date}</p> */}
            <p>{award.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};
export default Awards;
