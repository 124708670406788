const data = [
  {
    name: 'Army Achievement Medal',
    date: '09/2014',
    issuer: 'U.S. Army',
    description:
      'Awarded for exceeding Warrior Leader Course standards by demonstrating superior leadership and critical thinking skills in both professional and combat environments.',
  },
  {
    name: 'Army Achievement Medal',
    date: '04/2014',
    issuer: 'U.S. Army',
    description:
      'Awarded for maintaining high standards and influential leadership while in a combat zone.',
  },
  {
    name: 'Combat Action Badge',
    date: '12/2013',
    issuer: 'U.S. Army',
    description: 'Afghanistan.',
  },
];

export default data;
