import data from './data';

const Publications = () => {
  return (
    <section>
      <div className='mx-auto max-w-screen-lg'>
        <h1 className='mb-8 text-center'>Publications</h1>
        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3'>
          {data.map((publication, i) => (
            <div key={i} className='mb-8 last-of-type:mb-0'>
              <div className='mb-2 sm:flex sm:items-center sm:justify-between'>
                <h2>{publication.title}</h2>
                {/* <p className='hidden text-base italic sm:block'>{publication.date}</p> */}
              </div>
              <p className='bg-dark text-light mb-2 inline-block rounded-lg'>
                <a
                  href={publication.link}
                  target='_blank'
                  rel='noreferrer'
                  className='px-4'
                >
                  {publication.platform}
                </a>
              </p>
              {/* <p className='mb-2 text-base italic sm:hidden'>{publication.date}</p> */}
              <p>{publication.publisher}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
export default Publications;
